@import "./style/palette.module.scss";

@font-face {
  font-family: "Metropolis";
  src: url('../../fonts/metropolis/Metropolis-100.otf');
  font-weight: 100;
}

@font-face {
  font-family: "Metropolis";
  src: url('../../fonts/metropolis/Metropolis-200.otf');
  font-weight: 200;
}

@font-face {
  font-family: "Metropolis";
  src: url('../../fonts/metropolis/Metropolis-400.otf');
  font-weight: 400;
}

@font-face {
  font-family: "Metropolis";
  src: url('../../fonts/metropolis/Metropolis-700.otf');
  font-weight: 700;
}

@font-face {
  font-family: "Metropolis";
  src: url('../../fonts/metropolis/Metropolis-800.otf');
  font-weight: 800;
}

@font-face {
  font-family: "Metropolis";
  src: url('../../fonts/metropolis/Metropolis-900.otf');
  font-weight: 900;
}

@font-face {
  font-family: "Roboto";
  src: url('../../fonts/roboto/Roboto-Regular.ttf');
  font-weight: 400;
}

* {
  box-sizing: border-box;
  font-family: Metropolis;
}

html, body {
  margin: 0;
  overflow-x: hidden;
}

:focus {
  outline: none;
}

a {
  text-decoration: none;
  color: unset;
  &:active, &:hover, &:focus {
    outline: none;
    background-color: inherit;
  }
}

.swiper-button-prev {
  background-image: url("../../img/arrow-left.svg") !important;
  background-repeat: no-repeat;
  background-size: 28px 28px;
  background-position: center;
}

.swiper-button-prev::after {
  display: none;
}

.swiper-button-next {
  background-image: url("../../img/arrow-right.svg") !important;
  background-repeat: no-repeat;
  background-size: 28px 28px;
  background-position: center;
  height: 17px;
  width: 28px;
}

.swiper-button-next::after {
  display: none;
}